import { pattern } from "../constants/heads";

export const toWebp = (url: string) => {
    return url.replace(/(.*\/)*([^.]+).*/gi, "$2") + ".webp"
}

export const getQueryString = (name: string, url: string) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = url.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

export const deleteHtmlTag = (str: string) => {
    str = str.replace(/<[^>]+>|&[^>]+;/g, "").trim(); //去掉所有的html标签和&nbsp;之类的特殊符合
    return str;
}

export const limitWords = (txt: string, num: number) => {
    var str = txt;
    str = str.substr(0, num) + (str.length > num ? '...' : '');
    return str;
}

export const itemClick = (e: any,list:any,responsive:any,likeFun?:any) => { //委托事件
    const { isXs, isSm, isMd, isXxl } = responsive
    const id = e.target.getAttribute("data-id") || e.target.parentNode.parentNode.getAttribute("data-id")|| e.target.parentNode.parentNode.parentNode.getAttribute("data-id")
    const like = e.target.getAttribute("data-like") || e.target.parentNode.parentNode.getAttribute("data-like")|| e.target.parentNode.parentNode.getAttribute("data-like")
    console.log(like,id)
    if (like && id) {
      likeFun(id)
    } else if (id) {
      let item: any = ""
      list.map((v: { list: any[]; }, k: any) => {
        v.list.map((n: any) => {
          if (n._id == id) {
            item = n
          }
        })
      })
      window.open(!isXs || item.type == 2 ? `/api/link?id=${item._id}` : `/details/${pattern ? item._id : item.pinyin}`)
    }
  }