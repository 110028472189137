import itemStyles from '../components/styles/Item.module.scss';
// import Joyride from 'react-joyride';
import styles from './styles/index.module.scss';
import dynamic from 'next/dynamic'
import { Affix, Col, Row, Tag, Dropdown, Menu, Tabs, Modal, message } from 'antd'
import Layout from '../components/MyLayout'
// import Banner from '../components/Banner'
// import Item from '../components/Item'
import NoSsr from '../components/NoSsr';
const Item = dynamic(
  () => import("../components/Item")
)
const Banner = dynamic(
  () => import("../components/Banner")
)
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NextPage, NextPageContext } from 'next';
import { Right, Down } from '@icon-park/react';
import { List as TSList, Item as item, SearchList } from '../@types/index'
import { brief, colorPrimary, description, keywords, title, version } from '../constants/heads'
import { getIndexList, getList, indexSearchList, isLike } from '../constants/api'
import { http } from '../utils/api'
import { State } from '../@types/reducer';
import { itemClick } from '../utils';
const { TabPane } = Tabs;
const Joyride = dynamic(
  () => import("react-joyride"),
  { ssr: false }
)
const head = {
  title: title + brief,
  keywords: keywords,
  description: description
}

type Props = {
  searchList: SearchList[]
  data: {
    uuid: string,
    status: boolean,
    num: number | string,
    length: number,
    imgUrl: string,
    category_num: number | string,
    list: TSList[]
  }
}


const steps = [
  {
    target: ".sign",
    content: '登录即可全局自定义，支持自定义壁纸、布局、网址、搜索。',
    // disableBeacon:true
  },
  {
    target: ".search",
    content: '快捷搜索可助您快速搜索多站点内容，并且可实时翻译关键词。'
  },
  {
    target: ".setUp",
    content: '个性设置可进行网址内容设置，自定义布局、搜索设置、自定义壁纸。'
  },
  {
    target: ".service",
    content: '使用中遇到任何问题欢迎随时联系在线客服。我们非常愿意协助你解决问题。'
  },
]

const Index: NextPage<Props> = (props) => {
  const { imgUrl, length } = props.data
  const { userInfo, baseUrl, isSwitch, responsive } = useSelector((state: State) => state)
  const [isTour, setTour] = useState(false);
  const [navIndex, setNavIndex] = useState(0)
  const [list, setList] = useState(props.data.list)
  const { isXs, isSm, isMd, isXxl } = responsive
  let refs: (HTMLDivElement | null)[] = [];
  const handleScroll = () => {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    let i = 0
    refs.map((v, k) => {
      let el: any = v
      if (el != null && scrollTop + 70 >= el.offsetTop) {
        i = k
      }
    })
    if (
      scrollTop + document.documentElement.clientHeight ==
      document.body.scrollHeight
    ) {
      i = refs.length - 1
    } else if (scrollTop == 0) {
      i = 0
    }
    setNavIndex(i)
  }

  const navClick = (index: number | string) => {
    const i = Number(index), el: any = refs[i]
    document.body.scrollTop = document.documentElement.scrollTop = el.offsetTop - 60;
    setNavIndex(i)
  }

  const moreFun = async (index: number, data: TSList) => {
    let itemLength = list[index].length ? list[index].length : length
    if (data.num > length && itemLength == length) {
      if (!props.data.uuid) {
        const res: any = await http(getList, {
          home: false,
          by: "like",
          limit: 1000,
          category: data._id,
        })
        if (res.code == 200) {
          res.data.list.map((item: item) => {
            list[index].list.push(item);
          });
          list[index].length = data.num;
        }
      }
    } else {
      list[index].list.splice(length, data.num)
      list[index].length = length;
    }
    let el: any = refs[index]
    document.body.scrollTop = document.documentElement.scrollTop = el.offsetTop - 60;
    setList([...list])
  }

  const likeFun = async (id: string) => {
    let res: any = await http(isLike, { id })
    if (res.code == 200) {
      if (res.data.num == 1) {
        list.map((v, k) => {
          v.list.map((item: any) => {
            if (item._id == id) {
              item.like += 1
              item.like_status = true
            }
          })
        })
        message.success('收藏成功');
        setList(list.slice())
      } else {
        list.map((v, k) => {
          v.list.map((item: any) => {
            if (item._id == id) {
              item.like -= 1
              item.like_status = false
            }
          })
        })
        setList(list.slice())
      }
    }
  }

  useEffect(() => {
    // document.body.addEventListener("scroll", handleScroll.bind(this));
    let timeout: any = null;
    function scrollFun(this: any) {
      if (timeout !== null) clearTimeout(timeout);
      timeout = setTimeout(function () {
        handleScroll()
      }.bind(this), 100);
    }

    if (!localStorage.getItem(version.date)) { //判断版本更新
      Modal.info({
        title: null,
        icon: null,
        className: styles.isUpdate,
        width: '520px',
        content: version.html,
        onOk() {
          localStorage.setItem(version.date, 'true')
          setTour(true)
        },
      });
    }

    window.addEventListener("scroll", scrollFun);
    return () => {
      window.removeEventListener("scroll", scrollFun);
    }
  }, [])

  return (
    <Layout className={styles.index} head={head}>
      <Banner searchList={props.searchList}>
        <Affix className="nav" offsetTop={0}>
          {isXxl ?
            <div className="nav-list">
              {
                list.map((item, index) => (
                  index <= 10 ? <div className="btn" key={index} >
                    <Tag onClick={() => navClick(index)} color={index == navIndex ? colorPrimary : ''}>{item.name}</Tag>
                  </div> : ''
                ))
              }
              {
                list.length > 11 &&
                <div className="btn">
                  <Dropdown trigger={['hover']} overlay={
                    <Menu>
                      {
                        list.map((item, index) => (
                          index > 10 &&
                          <Menu.Item key={index}>
                            <a onClick={() => navClick(index)} style={{ textAlign: 'center' }}>
                              {item.name}
                            </a>
                          </Menu.Item>
                        ))
                      }
                    </Menu>
                  }>
                    <Tag>更多分类
                      {/* <DownOutlined style={{padding:'0 4px'}} /> */}
                    </Tag>
                  </Dropdown>
                </div>
              }
            </div> :
            <Tabs centered defaultActiveKey="1" size={isXs ? "small" : "middle"} tabBarGutter={30} activeKey={String(navIndex)} tabPosition={'top'} onTabClick={navClick}>
              {
                list.map((item, index) => (
                  <TabPane tab={item.name} key={String(index)} ></TabPane>
                ))
              }
            </Tabs>
          }
        </Affix>
      </Banner>
      <div className={styles.content} onClick={(e) => { itemClick(e, list, responsive,likeFun) }}>
        {list.map((item, index) => {
          return (
            <div className={itemStyles.item} ref={e => refs.push(e)} key={index}>
              {
                index != 0 &&
                <Row>
                  <Col flex="auto"><h3>{item.name}</h3></Col>
                  {item.num > 25 && !props.data.uuid &&
                    <Col className="more" onClick={() => moreFun(index, item)} flex="100px">
                      {item.length && item.length > length ?
                        <>收起<Down theme="outline" size="18" fill="#999" strokeWidth={3} strokeLinejoin="miter" /></> :
                        <>更多<Right theme="outline" size="18" fill="#999" strokeWidth={3} strokeLinejoin="miter" /></>
                      }
                    </Col>}
                </Row>
              }
              {/* {item.list.map((v: item, k: number) => {
                return (
                  index <= 1 ?
                    <Item isLazyLoad={index == 0 && k < 10 ? false : true} switch={isSwitch} imgUrl={imgUrl} key={k} data={v} /> :
                    <NoSsr key={k}><Item isLazyLoad switch={isSwitch} imgUrl={imgUrl} key={k} data={v} /></NoSsr>
                )
              })} */}
              {item.list.map((v: item, k: number) => {
                return (
                  index <= 3 ?
                  <Item isLazyLoad={index == 0 && k < 10 ? false : true} switch={isSwitch} imgUrl={imgUrl} key={k} data={v} /> :
                  <NoSsr key={k}><Item isLazyLoad switch={isSwitch} imgUrl={imgUrl} key={k} data={v} /></NoSsr>
                )
              })}
            </div>
          )
        })}
      </div>
      {isTour && <Joyride
        run={isTour}
        continuous
        disableScrolling
        steps={steps}
        showProgress
        showSkipButton
        locale={{ back: '后退', close: '关闭', last: '完成', next: '下一步', skip: '跳过' }}
        styles={{
          options: {
            primaryColor: colorPrimary,
            width: 340,
          }
        }}
      />}
    </Layout>
  )
}

Index.getInitialProps = async ({ req, store }: NextPageContext) => {
  const data: any[] = await Promise.all([
    http(getIndexList,
      { is_home: true }
      , req),
    http(indexSearchList, {}, req),
  ])
  return { data: data[0].data, searchList: data[1].data }
}

export default Index